import React, { Component, Fragment } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}




export default class EcomNav extends Component {
    render() {

        const { allMarketplaces } = this.props
        const { accounting } = this.props
        const { allCompetitors } = this.props



        return (


            < nav className={`flex items-center flex-wrap p-6 bg-${this.props.ecomColor} justify-center`}>
                <ul className="flex">
                    <li>
                        <img
                            className={`${this.props.logoWhiteSize} pr-5`}
                            src={this.props.logoWhite}
                            alt={`${this.props.ecomName} Accounting Software`}
                            placeholder="blurred"
                        />
                    </li>
                    <li className="px-6">
                        <Link className="text-white" activeClassName="text-white font-bold" to={`/${this.props.ecomUID}`}>Overview</Link>
                    </li>
                    <li>

                    </li>


                    <li>
                        {this.props.ecommercePlatform == "Amazon"
                            ?
                            <Popover.Group as="nav" className="hidden md:flex space-x-10 px-5">

                                <Popover className="relative">
                                    {({ open }) => (
                                        <>
                                            <Popover.Button
                                                className={classNames(
                                                    open ? 'text-white font-bold' : 'text-white',
                                                    'group inline-flex items-center text-base font-medium focus:outline-none'
                                                )}
                                            >
                                                <span>Marketplaces</span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? 'text-white' : 'text-white',
                                                        'ml-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel
                                                    static
                                                    className="absolute left-1/2 z-10 -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                                                >
                                                    <div className="rounded-lg shadow ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                        <div className="relative grid gap-6 bg-white px-2 py-6 sm:gap-8 sm:p-8">
                                                            {allMarketplaces.map((marketplace) => (
                                                                marketplace.data.marketplaces.map((marketplaces) => (
                                                                    marketplaces.marketplace_uid !== null ?

                                                                        < Link
                                                                            to={`/${marketplaces.marketplace_uid}`}
                                                                            className="-m-3 p-1 flex items-start rounded-lg hover:bg-gray-50 "
                                                                        >
                                                                            <div className="ml-4">
                                                                                <p className="text-base font-medium text-gray-700 border-b-2 border-amazon">{marketplaces.marketplace_name}</p>
                                                                            </div>
                                                                        </Link>
                                                                        : ""

                                                                ))
                                                            ))}

                                                            {/* < Link
                                                                to={`/amazon-ca-sales-tax`}
                                                                className="-m-3 p-1 flex items-start rounded-lg hover:bg-gray-50  "
                                                            >

                                                                <div className="ml-4">
                                                                    <p class="text-base font-medium text-gray-700 border-b-2 border-amazon">Amazon.ca</p>
                                                                </div>
                                                            </Link> */}

                                                        </div>




                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </Popover.Group>
                            :

                            <Popover.Group as="nav" className="hidden md:flex space-x-10 px-5">

                                <Popover className="relative">
                                    {({ open }) => (
                                        <>
                                            <Popover.Button
                                                className={classNames(
                                                    open ? 'text-white font-bold' : 'text-white',
                                                    'group inline-flex items-center text-base font-medium focus:outline-none'
                                                )}
                                            >
                                                <span>Integrations</span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? 'text-white' : 'text-white',
                                                        'ml-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel
                                                    static
                                                    className="absolute left-1/2 z-10 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                                                >
                                                    <div className="rounded-lg shadow ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                            {accounting.map((account) => (
                                                                <Link
                                                                    to={`/${this.props.ecomUID}/connect-${this.props.ecomUID}-${account.accounting_integration.document.uid}`}
                                                                    className="-m-3 p-2 flex items-start rounded-lg hover:bg-gray-50"
                                                                >
                                                                    <div className="shrink-0 text-blue-600" aria-hidden="true">
                                                                        <img className="h-16" src={account.accounting_integration.document.data.icon.url} alt={`${account.accounting_integration.document.data.short_name} Connect ${this.props.ecomName}`} />
                                                                    </div>

                                                                    <div className="ml-4">
                                                                        <p className="text-base font-medium text-gray-900">{account.accounting_integration.document.data.short_name} Integration</p>
                                                                        <p className="mt-1 text-sm text-gray-500">Connect {this.props.ecomName} with {account.accounting_integration.document.data.long_name}</p>
                                                                    </div>
                                                                </Link>
                                                            ))
                                                            }

                                                        </div>

                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </Popover.Group>

                        }
                    </li>


                    {this.props.competitorCheck !== undefined ?

                        <li>
                            <Popover.Group as="nav" className="hidden md:flex space-x-10 px-5">

                                <Popover className="relative">
                                    {({ open }) => (
                                        <>
                                            <Popover.Button
                                                className={classNames(
                                                    open ? 'text-white font-bold' : 'text-white',
                                                    'group inline-flex items-center text-base font-medium focus:outline-none'
                                                )}
                                            >
                                                <span>Compare To</span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? 'text-white' : 'text-white',
                                                        'ml-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel
                                                    static
                                                    className="absolute left-1/2 z-10 -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                                                >
                                                    <div className="rounded-lg shadow ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                        <div className="relative grid gap-6 bg-white px-2 py-6 sm:gap-8 sm:p-8">
                                                            {allCompetitors.map((competitor) => (

                                                                < Link
                                                                    to={`/${competitor.uid}-alternative-${this.props.ecomsUID}`}
                                                                    className="-m-3 p-1 flex items-start rounded-lg hover:bg-gray-50 "
                                                                >
                                                                    <div className="ml-4">
                                                                        <p className="text-base font-medium text-gray-700 border-b-2 border-blue-600">{competitor.data.name}</p>
                                                                    </div>
                                                                </Link>

                                                            ))}

                                                        </div>

                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </Popover.Group>



                        </li>

                        : ""

                    }

                    <li className="px-6">

                        <Link className="text-white" activeClassName="text-white font-bold" to="/pricing">Pricing</Link>


                    </li>
                </ul>
            </nav >

        );
    }
}