import React from "react";
import Layout from "../components/layout";
import { graphql } from 'gatsby';
import { StarIcon, ArrowLongRightIcon, CheckBadgeIcon, } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Link } from "gatsby";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import "../../node_modules/swiper/swiper-bundle.css";
import EcomNav from "../components/ecomNav"
import Howitworks from "../components/howitworks"
import Features from "../components/ecommerce-features"
// import { GatsbySeo } from "gatsby-plugin-next-seo";
import SEO from "../components/seo/seo-new"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';


SwiperCore.use([Navigation, Pagination]);

export const ecommerceQuery = graphql`
query MyQueryEcommerce($accountingPlatform: String, $ecommercePlatform: String, $ecomsUID: String, $language: String!) {
  prismicEcommerce(data: {ecommerce_name: {text: {eq: $ecommercePlatform}}}) {
    uid
    lang
    data {
      status
      ecommerce_name {
        text
      }
      marketplaces {
        marketplace_name
        marketplace_uid
      }
      logo {
        alt
        url
      }
      hero_image {
        url
      }
      color
      sign_up_link {
        url
      }
      logo_white_svg {
        url
      }
       logo_white_height
       text_color
       accounting_integration1 {
        accounting_integration {
          document {
            ... on PrismicAccounting {
              data {
                short_name
                color
                icon {
                  url
                }
                 logo {
                  url
                }
                long_name
              }
              uid
            }
          }
        }
      }
    }
  }
  allPrismicCompetitors(filter: {data: {ecom: {elemMatch: {ecom_uid: {eq: $ecomsUID}}}}}) {
        nodes {
            data {
                name
            }
            uid
        }
    }
  prismicAccounting(uid: {eq: $accountingPlatform}) {
    data {
      long_name
      short_name
      icon {
        alt
        url
      }
      logo {
        alt
        url
      }
    }
  }
   allPrismicEcommerce {
    nodes {
      data {
        marketplaces {
          marketplace_name
          marketplace_uid
        }
      }
    }
  }
  allPrismicTestimonial  (filter: {data: {ecom: {elemMatch: {ecommerce: {uid: {eq: $ecomsUID}}}}, ecommerce_featured: {eq: true}}}) {
    nodes {
      data {
        testimonial_text {
          html
        }
        seller_sales_amount
        review_location
        name
        ecom {
          ecommerce {
            uid
          }
        }
        ecommerce_featured
        avatar {
          url
        }
      }

    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  sitePage {
    context {
      i18n {
        defaultLanguage
        language
        languages
        originalPath
        routed
      }
    }
  }

}
`



export default function IndexPage({ data, pageContext }) {

  const { t } = useTranslation();

  // const url = `/${lang}/${data.prismicEcommerce.page}/`
  const ecom = data.prismicEcommerce.data
  const ecomName = pageContext.ecommerceName
  const ecommercePlatform = pageContext.ecommercePlatform
  const ecomUID = pageContext.ecommerceUID
  const lang = data.locales.edges[0].node.language
  const ecomColor = ecom.color
  const register = ecom.sign_up_link.url
  const logoWhite = ecom.logo_white_svg.url
  const logoWhiteSize = ecom.logo_white_height
  const ecomTextColor = ecom.text_color
  const ecomStatus = ecom.status
  const accounting = ecom.accounting_integration1
  const ecomsUID = pageContext.ecomsUID

  const amzMarketplace = ecom.ecommerce_name.text
  const allMarketplaces = data.allPrismicEcommerce.nodes
  const allPrismicCompetitors = data.allPrismicCompetitors.nodes
  const competitorCheck = allPrismicCompetitors[0]

  let description, title, url;


  ecomUID === "amazon" ?
    description = `${amzMarketplace} ` + t('seller accounting integration made easy. Best') + ` ${amzMarketplace} ` + t('accounting software to automate your') + ` ${amzMarketplace} ` + t('business. Get started with for free!')
    :
    description = `${ecomName} ` + t('seller accounting integration made easy. Best') + ` ${ecomName} ` + t('accounting software to automate and grow your') + ` ${ecomName} ` + t('business. Get started for free!')



  ecomUID === "amazon" ?
    title = `${amzMarketplace} ` + t('Accounting & Bookkeeping Automation Software | taxomate')
    :
    title = `${ecomName} ` + t('Accounting & Bookkeeping Automation Software | taxomate')

  const originalPathname = `/${pageContext.page}/`

  lang === "en" ?
    url = `/${pageContext.page}/`
    :
    url = `/${lang}/${pageContext.page}/`



  const testimonials = data.allPrismicTestimonial.nodes

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
        pathname={url}
        originalPathname={originalPathname}
        lang={lang}
        alternate
      />

      <EcomNav
        ecomUID={`${ecomUID}`}
        ecomName={`${ecomName}`}
        ecomColor={`${ecomColor}`}
        logoWhite={`${logoWhite}`}
        logoWhiteSize={`${logoWhiteSize}`}
        ecommercePlatform={`${ecommercePlatform}`}
        marketplace={`${amzMarketplace}`}
        allMarketplaces={allMarketplaces}
        accounting={accounting}
        allCompetitors={allPrismicCompetitors}
        ecomsUID={ecomsUID}
        competitorCheck={competitorCheck}
      />

      <section className="bg-white pb-8 sm:pb-12 lg:pb-6 align-items-center">
        <div className="pt-1 overflow-hidden sm:pt-12 lg:relative ">

          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div className="mt-1">

                <div className="sm:max-w-xl">
                  <h1 className="text-3xl font-bold text-gray-900 sm:text-5xl">
                    <Trans>Automated</Trans> <span className={`text-${ecomTextColor}`}>{ecomName} </span><Trans>Bookkeeping</Trans>
                  </h1>



                  <ul class="list-none pt-5">
                    <li class="flex items-center mb-2">
                      <div className="h-8 w-8">
                        <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
                      </div>
                      <span class="text-sm"> <Trans>Easily connect Amazon with your accounting software.</Trans></span>
                    </li>
                    <li class="flex items-center mb-2">
                      <div className="h-8 w-8">
                        <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
                      </div>
                      <span class="text-sm"> <Trans>Effortlessly keep track of Amazon sales & fees and make tax season a breeze.</Trans></span>
                    </li>
                    <li class="flex items-center mb-2">
                      <div className="h-8 w-8">
                        <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
                      </div>
                      <span class="text-sm"> <Trans>Save time, frustration, and above all else, money.</Trans></span>
                    </li>
                  </ul>

                </div>



                <div className="mt-6">



                  <div className="mt-6">
                    <a
                      href={`${register}`}
                      className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-blue-800 rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
                    >
                      {ecomStatus === "Coming Soon"
                        ? t('Notify When Ready')
                        : t('Try For Free')
                      }
                    </a>
                  </div>


                </div>

                <div className="pt-3 pl-1">

                  <p className="text-[11px]">
                    {ecomStatus === "Coming Soon"
                      ? 'Signup to be notified when we launch our ' + `${ecomName}` + ' integration.'

                      : 'Start Your 14 Day Free Trial'
                    }
                  </p>
                </div>

                <div className="mt-5">
                  <div className="inline-flex items-center divide-x divide-gray-300">
                    <div className="shrink-0 flex pr-5">
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                      <span className="font-medium text-gray-900"><Trans>Rated 5 stars</Trans></span> by{' '}
                      <Link to="/reviews">
                        <span className="font-medium text-blue-600"><Trans>hundreds of ecommerce Sellers</Trans></span>
                      </Link>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>



          <div className="sm:mx-auto sm:max-w-3xl sm:px-6 max-w-lg pb-10">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">

              <div className="relative pl-4 px-10 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-10 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full shadow rounded-md"
                  src={ecom.hero_image.url}
                  alt={`${ecomName} Bookkeeping Software`}
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="h-full bg-white px-4 py-8 ">
        <div className="flex flex-wrap justify-center gap-5">

          {accounting.map((account) => (


            <Link className={`transform hover:scale-105 bg-${account.accounting_integration.document.data.color} inline rounded-lg pt-0 pb-6 px-2 py-8 shadow border-2 border-blue-800 w-full md:w-1/4 gap-3`} to={`connect-${ecomUID}-${account.accounting_integration.document.uid}`}>
              <div className="flex items-center w-full justify-center mt-5">
                <img className="w-24" src={account.accounting_integration.document.data.logo.url} alt={`Connect ${ecomName} to ${account.accounting_integration.document.data.long_name}`} />
              </div>
              <div className=" absolute bottom-1 left-0 right-0 items-center text-black text-lg text-center">
                <Trans>Connect</Trans> {ecomName} <Trans>to</Trans> {account.accounting_integration.document.data.long_name}
              </div>
            </Link>

          ))
          }
        </div>
      </section>
      <Howitworks ecomName={`${ecomName}`} />
      <Features ecomName={`${ecomName}`} />


      <section className=" bg-gray-50 overflow-hidden md:py-10 lg:py-12">

        <div className="relative mx-auto">

          <div className="py-20">
            <Swiper
              breakpoints={{
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                768: {
                  width: 1400,
                  slidesPerView: 2,
                },
              }}
              centeredSlides={true}
              spaceBetween={30}
              grabCursor={true}
              autoHeight={true}

              autoplay={{ delay: 10000 }}
              pagination={{ clickable: true }}
              loop
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              modules={[Navigation, Pagination, Autoplay]}

            >
              {testimonials.map((testimonial) => (


                <SwiperSlide>
                  <div className="container mx-auto px-4">
                    <div className="mx-auto py-3 bg-white border-2 border-blue-800 shadow rounded-lg">
                      <div className="max-w-4xl p-4 flex flex-wrap justify-center items-center">
                        <div className="mb-6 w-full lg:w-1/3 text-center align-middle">
                          <img
                            className="mb-6 mx-auto w-20 h-20 rounded-full object-cover"
                            src={testimonial.data.avatar.url}
                            alt="ecommerce integration service"
                          />
                          <h4 className="text-lg">{testimonial.data.name}</h4>
                          <p className="text-blue-600">{testimonial.data.seller_sales_amount}</p>
                        </div>
                        <div className="w-full lg:w-2/3">
                          <div className="hrink-0 flex pr-5 py-2">
                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          </div>
                          <h4 className="mb-6 text-md font-heading">
                            <div dangerouslySetInnerHTML={{ __html: testimonial.data.testimonial_text.html }} />

                          </h4>
                          <div className="inline-flex items-center text-sm font-bold text-blue-600">

                            <CheckBadgeIcon className="h-9 w-9 px-2" />
                            {testimonial.data.review_location === "QuickBooks" ? `Verified — QuickBooks App Store` : ``}
                            {testimonial.data.review_location === "Xero" ? `Verified — Xero App Store` : ``}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

              ))
              }
            </Swiper>
            <div className="text-center py-6">
              <Link
                to="/reviews"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-blue-800 shadow rounded-md text-base font-medium text-white bg-blue-600 hover:bg-white hover:text-blue-600"
              >
              <Trans>See More Reviews</Trans>
                <div className="px-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </div>
              </Link>
            </div>
          </div>


        </div>

      </section>


      <div className={`bg-${ecomTextColor}`}>
        <div className="max-w-7xl mx-auto text-center py-12 pb-10 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="  text-white sm:text-3xl">

            <span className="block">

              {ecomStatus === "Coming Soon"
                ? t('Want to Be Notified When We Launch?')
                : t('Streamline your bookkeeping processes today and experience the difference Taxomate can make.')
              }
            </span>
            <span className="block font-extrabold pt-6 pb-3">
              {ecomStatus === "Coming Soon"
                ? ''
                : t('Start your free trial today.')
              }
            </span>
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href={`${register}`}
                className="inline-flex items-center justify-center px-5 py-3 border-2 border-blue-800 shadow text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                {ecomStatus === "Coming Soon"
                  ? t('Register')
                  : t('Get Started')
                }              </a>
            </div>
            <div className="ml-3 inline-flex">
              {
                ecommercePlatform === "Amazon"
                  ?
                  <Link
                    to="/pricing"
                    className="inline-flex items-center justify-center px-5 py-3 border-2 border-blue-800 shadow  text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
                  >
                  <Trans>Pricing</Trans>
                  </Link>
                  :
                  <Link
                    to={`/pricing`}
                    className="inline-flex items-center justify-center px-5 py-3border-2 border-blue-800 shadow text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
                  >
          <Trans>Pricing</Trans>
                  </Link>
              }

            </div>
          </div>
        </div>
      </div>


    </Layout >
  );
}
