import React, { Component } from "react";
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { Trans } from 'gatsby-plugin-react-i18next';

export default class Howitworks extends Component {

  render() {

    const steps = [
      {
        step: '1',
        title: 'CONNECT' + ` ${this.props.ecomName} ` + 'AND YOUR ACCOUNTING SOFTWARE',
        text: 'Get started in minutes by connecting taxomate to your' + ` ${this.props.ecomName} ` + 'account and accounting software using our easy guided setup process.'
      },
      {
        step: '2',
        title: 'IMPORT' + ` ${this.props.ecomName} ` + 'FINANCIALS INTO TAXOMATE',
        text: 'Every time' + ` ${this.props.ecomName} ` + 'pays you, taxomate automatically imports financial settlements from' + ` ${this.props.ecomName} ` + 'including your sales, fees, and more.',
      },
      {
        step: '3',
        title: 'SEND INVOICE TO ACCOUNTING SOFTWARE',
        text: 'Send a journal entry or sales invoice to your accounting software containing all of your sales and fees. The invoice amount sent to your accounting software and the payment from'  + ` ${this.props.ecomName} ` +  'will match exactly.'
      },



    ]

    return (

      <div className="py-8 bg-white overflow-hidden lg:py-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative">
            <h2 className="text-3xl text-blue-600/100 tracking-tight sm:text-5xl pb-5 justify justify-center text-center">
              How does taxomate work?
            </h2>

          </div>

          <div className="relative mt-8 lg:mt-8 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div class="flex flex-wrap w-full">

              <div class="w-full py-6 content center">


                {steps.map((step) => (
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                  }}
                >
                  <div class="flex relative pb-8">

                    <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                      <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-600 inline-flex items-center justify-center text-white relative z-10">
                      {step.step}
                    </div>
                    <div class="flex-grow pl-4">
                        <h3 class="font-medium title-font uppercase text-md text-gray-700 mb-1 tracking-wider"><Trans>{step.title}</Trans></h3>
                        <p class="leading-relaxed text-gray-500"><Trans>{step.text}</Trans></p>
                    </div>
                  </div>
              </motion.div>

              ))}
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 2 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                  }}
                >
                  <div class="flex relative">
                    <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-600 inline-flex items-center justify-center text-white relative z-10">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </div>
                    <div class="flex-grow pl-4">
                      <h2 class="font-medium title-font text-md uppercase text-gray-700 mb-1 tracking-wider"><Trans>Accurate and Automatic Books</Trans></h2>
                      <p class="leading-relaxed text-gray-500"><Trans>Reconciling usually takes just one click. That's it! Save hundreds of hours a year by automatically syncing</Trans> {this.props.ecomName} <Trans>with your accounting software going forward.</Trans></p>
                    </div>
                  </div>
                </motion.div>



                {/* <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                  }}
                >
                <div class="flex relative pb-8">

                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-600 inline-flex items-center justify-center text-white relative z-10">
                    1
                  </div>
                  <div class="flex-grow pl-4">
                      <h3 class="font-medium title-font uppercase text-md text-gray-700 mb-1 tracking-wider"><Trans>Connect</Trans> {this.props.ecomName} <Trans>and Your Accounting Software</Trans></h3>
                    <p class="leading-relaxed text-gray-500"><Trans>Connect taxomate to your</Trans> {this.props.ecomName} <Trans>account and accounting software using our easy guided setup process.</Trans></p>
                  </div>
                  </div>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1.25 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                  }}
                >
                <div class="flex relative pb-8">
                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-600 inline-flex items-center justify-center text-white relative z-10">
                    2
                  </div>
                  <div class="flex-grow pl-4">
                      <h2 class="font-medium title-font text-md uppercase text-gray-700 mb-1 tracking-wider"><Trans>Import</Trans> {this.props.ecomName} <Trans>Payouts into taxomate</Trans></h2>
                      <p class="leading-relaxed text-gray-500"><Trans>Every time</Trans> {this.props.ecomName} <Trans>makes a payment to you,</Trans> {this.props.ecomName} <Trans>provides you with a summarized settlement. After your initial setup, taxomate automatically imports these settlements when they become available from</Trans> {this.props.ecomName}.</p>
                  </div>
                  </div>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1.5 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                  }}
                >
                <div class="flex relative pb-8">
                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                    3
                  </div>
                  <div class="flex-grow pl-4">
                    <h2 class="font-medium title-font uppercase text-md text-gray-700 mb-1 tracking-wider">{ `Map Accounts & Taxes`}</h2>
                    <p class="leading-relaxed text-gray-500"><Trans>Apply any accounts and tax codes to your</Trans> {this.props.ecomName} <Trans>transaction types or use our default mappings. Our system is completely customizable to meet your needs.</Trans></p>
                  </div>
                  </div>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1.75 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                  }}
                >
                <div class="flex relative pb-8">
                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-600 inline-flex items-center justify-center text-white relative z-10">
                    4
                  </div>
                  <div class="flex-grow pl-4">
                    <h2 class="font-medium title-font text-md uppercase text-gray-700 mb-1 tracking-wider"><Trans>Send Invoice to Accounting Software</Trans></h2>
                      <p class="leading-relaxed text-gray-500"><Trans>Send a journal entry or sales invoice to your accounting software containing all of your sales and fees. The invoice amount sent to your accounting software and the payment from</Trans> {this.props.ecomName} <Trans>will match exactly.</Trans></p>
                  </div>
                  </div>
                </motion.div> */}

              </div>
            </div>

            <div className="mt-10 mx-4 relative lg:mt-0 hidden sm:block" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
              </svg>
              <StaticImage
                src="../images/settlements.png"
                alt="{this.props.ecomName} settlements quickbooks"
                placeholder="blurred"
              />
            </div>
          </div>



        </div>
      </div>
    );
  }
}
